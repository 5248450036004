<template>
  <CardTable
    :title="title"
    :subTitle="subTitle"
    :searchText="searchText"
    :columns="table.columns"
    :rows="table.rows"
    :isLoading="table.isLoading"
    :pageSize="serverParams.pageSize"
    :pageNumber="serverParams.pageNumber"
    :totalPage="table.totalPage"
    :totalRecords="table.totalRecords"
    @onRowSelected="onRowSelected"
    @onPerPageChange="onPerPageChange"
    @onPageChange="onPageChange"
    @onSearch="onSearch"
  >
    <template #filter>
      <b-row class="p-3">
        <b-col xl="4">
          <b-input
            v-model="serverParams.transactionDate"
            type="date"
            @input="onFilter"
          ></b-input>
        </b-col>
        <b-col xl="4">
          <treeselect
            ref="MEMBER"
            class="mb-2"
            placeholder="Pilih Pelanggan"
            v-model="serverParams.memberId"
            :async="true"
            :loadOptions="getAllMember"
            @input="onFilter"
          />
        </b-col>
        <b-col xl="4">
          <treeselect
            class="mb-2"
            placeholder="Pilih Kasir"
            v-model="serverParams.cashierId"
            :options="options.cashier"
            @input="onFilter"
          />
        </b-col>
      </b-row>
    </template>
    <template #empty>
      <EmptyTable
        title="Tidak ada data hutang yang bisa ditampilkan"
        :description="subTitle"
      />
    </template>
    <template #cell(created.createdAt)="data">
      {{ dateTimeFormat(data.value) }}
    </template>
    <template #cell(quantity)="data">
      {{ numberFormat(data.value) }}
    </template>
    <template #cell(amount)="data">
      {{ numberFormat(data.value) }}
    </template>
    <template #cell(grandTotal)="data">
      {{ numberFormat(data.value) }}
    </template>
    <template #cell(paymentRemaining)="data">
      {{ numberFormat(data.value) }}
    </template>
  </CardTable>
</template>

<script>
import { mapGetters } from "vuex";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { hutang as columns } from "@/core/datasource/column";
import {
  pageSize,
  dateTimeFormat,
  numberFormat,
  normalizer,
  getCashier,
} from "@/core/utils";

export default {
  data: () => ({
    title: "Transaksi Outstanding",
    subTitle: "Menampilkan transaksi outstanding",
    searchText: "Cari nomor transaksi",
    serverParams: {
      pageNumber: 1,
      pageSize,
      transactionNumber: null,
      transactionDate: null,
      memberId: null,
      cashierId: null,
      orderBy: "",
    },
    table: {
      isLoading: false,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
    options: {
      member: [],
      cashier: [],
    },
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  created() {
    const self = this;

    getCashier(self.store.id).then((response) => {
      self.options.cashier = response.data.map((x) => ({
        id: x.id,
        label: x.fullName,
      }));
    });

    self.getDefaultMember();
    self.getAll();
  },
  methods: {
    dateTimeFormat,
    numberFormat,
    normalizer,
    getDefaultMember() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: `/store/${self.store.id}/member`,
          params: {
            pageNumber: 1,
            pageSize,
            actived: true,
          },
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.$refs.MEMBER.defaultOptions = response.data.data.map((x) => ({
              id: x.id,
              label: x.fullName,
            }));
            self.$refs.MEMBER.initialize();
          }
        });
    },
    getAllMember({ action, searchQuery, callback }) {
      const self = this;
      if (action === ASYNC_SEARCH) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/member`,
            params: {
              fullName: searchQuery,
              pageNumber: 1,
              pageSize,
              actived: true,
            },
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              callback(
                null,
                response.data.data.map((x) => ({
                  id: x.id,
                  label: x.fullName,
                }))
              );
            }
          });
      }
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/outstanding`,
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
        })
        .finally(() => (self.table.isLoading = false));
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onRowSelected(items) {
      const self = this;
      self.$router.push({
        name: "outstandingUpdate",
        params: {
          id: items[0].id,
        },
      });
    },
    onPerPageChange(params) {
      const self = this;
      self.updateParams({ pageSize: params, pageNumber: 1 });
      self.getAll();
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getAll();
    },
    onSearch(params) {
      const self = this;
      self.updateParams({
        transactionNumber: params,
      });
      self.getAll();
    },
    onFilter() {
      const self = this;
      self.getAll();
    },
  },
};
</script>
