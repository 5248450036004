export const laporanMembership = [
  {
    label: "",
    key: "image",
    thClass: "align-middle w-5px",
    tdClass: "align-middle",
  },
  {
    label: "Member",
    key: "membership",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Identitas",
    key: "identity",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Jenis Kelamin",
    key: "gender.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tempat, Tanggal Lahir",
    key: "placeOfBirth",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Kontak",
    key: "email",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Nomor Kartu",
    key: "memberCard.cardNumber",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Masa Berlaku Member",
    key: "memberCard.validUntil",
    thClass: "align-middle text-center",
    tdClass: "align-middle text-center",
  },
  {
    label: "Paket Membership",
    key: "memberCard.product",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tanggal Registrasi",
    key: "created.createdAt",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const laporanShift = [
  {
    label: "Store",
    key: "store.storeName",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Starting Shift",
    key: "startDate",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Ending Shift",
    key: "endDate",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Sold Items",
    key: "soldItems",
    thClass: "align-middle text-center",
    tdClass: "align-middle text-center",
  },
  {
    label: "Expense Items",
    key: "expenseItems",
    thClass: "align-middle text-center",
    tdClass: "align-middle text-center",
  },
  {
    label: "Sales",
    key: "sales",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Outstanding",
    key: "outstanding",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Expense",
    key: "expense",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Nama Kasir",
    key: "cashier.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const laporanPenjualan = [
  {
    label: "Store",
    key: "store.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Nomor Transaksi",
    key: "transactionNumber",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tanggal",
    key: "created.createdAt",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Pelanggan",
    key: "customer.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Produk",
    key: "productName",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Kuantiti",
    key: "quantity",
    thClass: "align-middle text-center",
    tdClass: "align-middle text-center",
  },
  /* {
    label: "Harga Modal",
    key: "cogm",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  }, */
  {
    label: "Harga Jual",
    key: "price",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Diskon",
    key: "discount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Total Harga",
    key: "fixedPrice",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Nama Kasir",
    key: "created.createdBy",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const laporanTransaksi = [
  {
    label: "Store",
    key: "store.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Nomor Transaksi",
    key: "transactionNumber",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tanggal Transaksi",
    key: "created.createdAt",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Pelanggan",
    key: "customer.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Produk",
    key: "carts",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Sub Total",
    key: "subTotal",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Pajak",
    key: "tax",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Diskon",
    key: "discount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Grand Total",
    key: "grandTotal",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Jumlah Bayar",
    key: "amount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Kurang Bayar",
    key: "paymentRemaining",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Metode Pembayaran",
    key: "paymentMethod.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Nama Kasir",
    key: "created.createdBy",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const userManagement = [
  {
    label: "Nama Lengkap",
    key: "fullName",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Email",
    key: "email",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Nomor Whatsapp",
    key: "phone",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Pemilik Bisnis ?",
    key: "businessOwner",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Store",
    key: "stores",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Last Signed In",
    key: "lastSignedIn",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Status",
    key: "actived",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "",
    key: "action",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const paymentMethod = [
  {
    label: "",
    key: "image",
    thClass: "align-middle w-5px",
  },
  {
    label: "Nomor Urut",
    key: "sequenceNumber",
    thClass: "align-middle w-60px",
    tdClass: "align-middle text-center",
  },
  {
    label: "Kategori",
    key: "group.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Label",
    key: "label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Keterangan",
    key: "remarks",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Status",
    key: "actived",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const storeProduct = [
  {
    label: "Nama Produk",
    key: "product.productName",
    thClass: "align-middle",
  },
  {
    label: "Deskripsi",
    key: "product.description",
    thClass: "align-middle",
  },
  {
    label: "Kategori",
    key: "product.category",
    thClass: "align-middle",
  },
  {
    label: "VAT",
    key: "vat",
    thClass: "align-middle",
  },
  {
    label: "HPP",
    key: "cogm",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Harga Normal",
    key: "regularPrice",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Ketersediaan Produk",
    key: "actived",
    thClass: "align-middle",
  },
];

export const store = [
  {
    label: "",
    key: "logo",
    thClass: "align-middle w-5px",
  },
  {
    label: "Nama Store",
    key: "storeName",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Alamat",
    key: "address",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Kota",
    key: "city",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Provinsi",
    key: "province",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Layanan",
    key: "services",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Pajak Penjualan",
    key: "taxPercentage",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Club Member System",
    key: "clubMemberSystem",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Status",
    key: "actived",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const product = [
  {
    label: "Nama Produk",
    key: "productName",
    thClass: "align-middle",
  },
  {
    label: "Deskripsi",
    key: "description",
    thClass: "align-middle",
  },
  {
    label: "Kategori",
    key: "category",
    thClass: "align-middle",
  },
  {
    label: "VAT",
    key: "vat",
    thClass: "align-middle",
  },
  {
    label: "HPP",
    key: "cogm",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Harga Normal",
    key: "regularPrice",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
  {
    label: "Ketersediaan Produk",
    key: "actived",
    thClass: "align-middle",
  },
];

export const posSystem = [
  {
    label: "Nama Produk",
    key: "product.productName",
    thClass: "align-middle",
  },
  {
    label: "Harga Normal",
    key: "regularPrice",
    thClass: "align-middle text-right",
    tdClass: "text-right",
  },
];

export const pengeluaran = [
  {
    label: "Nomor Transaksi",
    key: "transactionNumber",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tanggal",
    key: "transactionDate",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Peruntukan",
    key: "expense",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Kuantiti",
    key: "quantity",
    thClass: "align-middle text-center",
    tdClass: "align-middle text-center",
  },
  {
    label: "Harga Satuan",
    key: "amount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Jumlah Pengeluaran",
    key: "grandTotal",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Nama Kasir",
    key: "created.createdBy",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const hutang = [
  {
    label: "Nomor Transaksi",
    key: "transactionNumber",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tanggal Transaksi",
    key: "created.createdAt",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Pelanggan",
    key: "customer.label",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Jumlah Hutang",
    key: "grandTotal",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Sudah Bayar",
    key: "amount",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Kurang Bayar",
    key: "paymentRemaining",
    thClass: "align-middle text-right",
    tdClass: "align-middle text-right",
  },
  {
    label: "Nama Kasir",
    key: "created.createdBy",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];

export const member = [
  {
    label: "",
    key: "image",
    thClass: "align-middle w-10px",
    tdClass: "align-middle",
  },
  {
    label: "Nomor Member",
    key: "membershipNumber",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Nama Lengkap",
    key: "fullName",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Identitas",
    key: "identity",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Jenis Kelamin",
    key: "gender",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tempat, Tanggal Lahir",
    key: "birth",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Tanggal Pendaftaran",
    key: "createdAt",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
  {
    label: "Kartu Member",
    key: "memberCard",
    thClass: "align-middle",
    tdClass: "align-middle",
  },
];
